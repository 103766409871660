@media only screen and (max-width: 1024px) {
  .mainimage{
    height: 100vh;
    margin-bottom: -30rem;
  }

  .headerContainer{
    max-width: 100%;
    left:0;
  }

  .TitleContainer{
    margin: 0;
    text-align: center;
    margin:auto;
  }
}

.headerContainer{
  position:"relative";left:"10vw";max-width:"50%";margin: bottom -10rem;
}

.mainimage{
  position:"relative";
  object-fit:"cover";
  top:0;
  left:0;
  height:"70vh";
  width:"100vw";
  margin-bottom:"-16rem";
}

.Main{
  background-color: #fff;
  left:0;
  display: flex;
  flex-direction:column;
  overflow: hidden;
}

.Slider{
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction:column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height:50vh;
  width: 100%;
  transition: all 0.3s ease;
}


.TitleContainer{
  position: relative;
  font-family: "Poppins", sans-serif;
  margin-bottom:2rem;
}

.Title {
    color:#000;
    display: flex;
    flex-direction:column;
    width: 60%;
    align-self: center; 
    border: 6px solid #000000;
    padding: 10px 25px;
}

/*.Title {
    color:#fff;
    display: flex;
    flex-direction:column;
    border: 3px solid #eee513;
    padding: 10px 25px;
    width: 60%;
    align-self: center; 
}*/

.subheading
{
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction:column;
  color:#8b8b8b;
  text-align: center;
  vertical-align: top;
  padding-left: 20%;
  padding-right: 20%;
}



.TeamWrapper{
  font-size: 0;
  border: 0;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items:center;
  flex-wrap: wrap;
  margin: 2rem;
}

.TeamWrapper .TeamCard{
  float:left;
  margin:6px;
  position: relative;
}

.TeamCard .MemberImage
{
  height: 260px;
  width: 320px;
  object-fit: fill;
}

.TeamCard .MemberOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .2s ease;
  background-color: rgba(255,255,255,0.8);
}

.TeamCard .MemberRank{
  color:black;
  font-size: 14px;
  position:absolute;
  font-family:'Poppins', sans-serif;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}


.TeamCard:hover .MemberOverlay{
  opacity: 1;
}

@media only screen and (max-width: 1024px) {
  .TitleContainer{
    font-size:40px;
  }
}


@media only screen and (max-width: 600px) {
  .TitleContainer{
    font-size:30px;
  }
}