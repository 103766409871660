.model {
    height: 100%;
    width: 100%;
    background-image: url("https://wallpaperaccess.com/full/1140482.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    min-width: 300px;
}
.model {

}

.scrollClicker {
    position: absolute;
    width: 250px;
    top: 300px;
    right: -160px;
    cursor: pointer;
    display: flex;
}

@media (max-width: 968px) {
    .scrollClicker {
        top: 200px;
        right: -150px;
    }
}
