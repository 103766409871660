.box p {
    color: hsl(229, 6%, 66%);
}


.subheading
{
  display: flex;
  flex-direction:column;
  text-align: center;
  vertical-align: top;
  padding-left: 20%;
  padding-right: 20%;
}


.heading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: black;
    font-size: 1.6em;
    margin-bottom: 0;
}

.box {
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px hsl(229, 6%, 66%);
    padding: 30px;
    margin: 20px;
}

img {
    float: right;
}

@media (max-width: 450px) {
    .box {
        height: 200px;
    }
}

@media (max-width: 950px) and (min-width: 450px) {
    .box {
        text-align: center;
        height: 180px;
    }
}

.cyan {
    border-top: 3px solid hsl(180, 62%, 55%);
}
.red {
    border-top: 3px solid hsl(0, 78%, 62%);
}
.blue {
    border-top: 3px solid hsl(212, 86%, 64%);
}
.orange {
    border-top: 3px solid hsl(34, 97%, 64%);
}

h2 {
    color: hsl(234, 12%, 34%);
    font-weight: 600;
}


@media (min-width: 950px) {
    .row1-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .row2-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .box-down {
        position: relative;
        top: 150px;
    }
    .box {
        width: 20%;
    }
    .header p {
        width: 30%;
    }

}
