.sidedrawer {
  position: fixed;
  width: 280px;
  max-width: 70%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999999999999999;
  /*background-color: #ede75a;*/
  padding: 32px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: rgba(21, 19, 19, 0.65);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18); */

  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
  background-color: rgba(224, 233, 233, 0.89);
  border: 1px solid rgba(255, 255, 255, 0.125);

}

@media (min-width: 720px) {
  .sidedrawer {
    display: none;
  }
}

.open {
  transform: translateX(0);
}

.close {
  transform: translateX(-100%);
}

.sidedrawer .sidedrawer__name {
  margin-top:10px;
  font-family: "Poppins", sans-serif;
  padding:0;
  border:0;
  align-items: center;
  justify-content: center;
  width: 100%;
  color:#282727;
}

.sidedrawer .sidedrawer__links {
  text-transform: none;
  font-family: "Poppins", sans-serif;
  font-size:15px;
  letter-spacing: 0px;
  height:9vh;
  margin:0;
  border:0;
  padding:0;
  width: 100%;
  color:#282727;
  font-weight: bold;
}

.sidedrawer .sidedrawer__back{
  height:50px;
  width:50px;
  left:2vw;
  top:2vh;
  position: absolute;
  cursor: pointer;
}
