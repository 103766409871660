.Project_container {
  top: 4vw;
  background-color: #f0f0f0;
}

#FIRST {
  height: 200vw;
  flex-wrap: wrap;
}

.panel_projects {
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.nav-link {
  color: black;
  text-decoration: none;
  margin: 0.4vw;
  font-family: Carrois Gothic SC;
  font-style: normal;
  font-weight: normal;
}

.projects_main_card_container:hover .projects_card_detail_container {
  opacity: 1;
}

#Projects_Main_Item_Container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.projects_main_card_container {
  width: 31vw;
  border: 1px black solid;
  margin: 0.9vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projects_image_card_container {
  width: 100%;
}

.projects_reponsive_image {
  width: 100%;
}

.projects_card_detail_container {
  position: absolute;
  overflow: hidden;
  height: 46vw;
  width: inherit;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  opacity: 0;
  transition: opacity .2s, display .2s
}

.projects_card_detail_para {
  padding: 2vw;
  font-size: 1vw;
  font-family: Poppins;
}

.Projects_description_para {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: inherit;
  align-items: center;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE 11 */
  scrollbar-width: none;
  /* Firefox 64 */
}

.Projects_description_para::-webkit-scrollbar {
  display: none;
}

.projects_card_heading {
  font-size: 1.4vw;
  font-family: Poppins;
}

@media screen and (max-width:800px) {
  #lottie {
    width: 103vw !important;
  }
  .Project_container {
    top: 14vw;
  }
  #Main_page_heading {
    font-size: 6.5vw !important;
  }
  #FIRST {
    height: 1350vw !important;
  }
  .projects_main_card_container {
    width: 74vw !important;
  }
  .projects_card_detail_container {
    height: 105vw !important;
  }
  .projects_card_heading {
    font-size: 4.1vw !important;
  }
  .projects_card_detail_para {
    font-size: 3vw;
  }
}

@media screen and (max-width:940px) and (min-width:800px) {
  #lottie {
    width: 83vw !important;
  }
  .Project_container {
    top: 10vw;
  }
  #Main_page_heading {
    font-size: 6vw !important;
  }
  #FIRST {
    height: 450vw !important;
  }
  .projects_main_card_container {
    width: 48vw !important;
  }
  .projects_card_detail_container {
    height: 69vw !important;
  }
  .projects_card_heading {
    font-size: 3.7vw !important;
  }
  .projects_card_detail_para {
    font-size: 2.5vw;
  }
}

@media screen and (max-width:1070px) and (min-width:940px) {
  #lottie {
    width: 83vw !important;
  }
  .Project_container {
    top: 5vw;
  }
  #Main_page_heading {
    font-size: 6vw !important;
  }
  #FIRST {
    height: 450vw !important;
  }
  .projects_main_card_container {
    width: 48vw !important;
  }
  .projects_card_detail_container {
    height: 68vw !important;
  }
  .projects_card_heading {
    font-size: 3.7vw !important;
  }
  .projects_card_detail_para {
    font-size: 2.5vw;
  }
}

@media screen and (max-width:1302px) and (min-width:1070px) {
  #lottie {
    width: 75vw !important;
  }
  .Project_container {
    top: 4vw;
  }
  #Main_page_heading {
    font-size: 5vw !important;
    margin: 0px !important;
  }
  #FIRST {
    height: 380vw !important;
  }
  .projects_main_card_container {
    width: 40vw !important;
  }
  .projects_card_detail_container {
    height: 60vw !important;
  }
  .projects_card_heading {
    font-size: 3vw !important;
  }
  .projects_card_detail_para {
    font-size: 2vw;
  }
}

@media screen and (max-width:1474px) and (min-width:1302px) {
  #lottie {
    width: 70vw !important;
  }
  .Project_container {
    top: 5vw;
  }
  #Main_page_heading {
    font-size: 4vw !important;
    margin: 0px !important;
  }
  #FIRST {
    height: 190vw !important;
  }
  .projects_main_card_container {
    width: 30vw !important;
  }
  .projects_card_detail_container {
    height: 43vw !important;
  }
  .projects_card_heading {
    font-size: 2vw !important;
  }
  .projects_card_detail_para {
    font-size: 1.5vw;
  }
}