.recruitment__page {
  display: flex;
  flex-direction: column;
  height: 200vh;
  background: white;
}

.recruitment__info__div {
  display: flex;
  flex: 0.25;
  flex-direction: column;
  margin: 60px 10px 50px 10px;
  align-items: center;
}

.recruitment__info__header {
  text-transform: capitalize;
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 3em;
  font-family: "Poppins", sans-serif;
}

.recruitment__info {
  color: #8b8b8b;
  font-family: "Poppins", sans-serif;
  font-size: 1.2em;
  font-weight: 400;
  margin: 0px 200px;
  text-align: center;
}

.recruitment__form__div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.70;
  margin: 30px 0px;
}

@media (max-width: 720px) {
  .recruitment__form__div > iframe {
    min-width: 90%;
  }
}
@media (max-width: 720px) {
  .recruitment__info {
    font-weight: 300;
    font-size: medium;
    width: 90%
  }
}
@media (max-width: 720px) {
  .recruitment__info__header {
    font-size: 2.3em;
  }
}

.recruitment__form__soon {
  color: black;
  font-size: xx-large;
  display: flex;
  flex-direction: column;
  margin: 50px;
}
