.Main{
  justify-content: center;
  align-items:center;
  width:100%;
  overflow: hidden;
  background-color: #f0f0f0;
}

.TitleContainer{
  color:black;
  display: flex;
  flex-direction:column;
  margin-top: 3rem;
  text-align: center;
  height:10%;
  padding: 10px 25px;
  font-size: 40px;
  margin-bottom: 3rem;
}

.Title {
    opacity: 1;
    color:black;
    display: flex;
    flex-direction:column;
    padding: 10px 25px;
    font-size: larger;
    width: 60%;
    align-self: center;
}


.Slider{
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction:column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height:50vh;
  width: 100%;
  transition: all 0.3s ease;
}

.Slider .SliderButtonleft{
  position:absolute;
  top:40%;
  left:3%;
  background-color: rgba(255,255,255,0.5);
  border: none;
  color: black;
  padding: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  height:25px;
  width:25px;
  border-radius: 50%;
  cursor: pointer;
}

.Slider .SliderButtonright{
  position:absolute;
  top:40%;
  right:3%;
  background-color: rgba(255,255,255,0.5);
  border: none;
  color: black;
  padding: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  height:25px;
  width:25px;
  border-radius: 50%;
  cursor: pointer;
}

.SliderButtonright:hover{
  background-color: rgba(255,255,255,0.8);
}

.SliderButtonleft:hover{
  background-color: rgba(255,255,255,0.8);
}


.subheading
{
  display: flex;
  flex-direction:column;
  color: #8b8b8b;
  text-align: center;
  vertical-align: top;
  padding-left: 20%;
  padding-right: 20%;
}


.heading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #8b8b8b;
    font-size: 1.6em;
    margin-bottom: 0;
}

.DepartmentButtonContainer{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items:center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  max-height: 20%;
  font-size: 40px;
}

.DepartmentButton{
    color:black;
    overflow: hidden;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction:column;
    font-weight: bold;
    border: 5px solid #eee513;
    border-radius: 15px;
    padding: 5px 5px;
    min-width: 30%;
    transition: .1s ease;
}

.DepartmentButton:hover{
    color:black;
}

.DepartmentButtonStyle1
{
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: blackee00;
    transform: translateY(150%);
    border-radius: 50%;
    left: calc((1 - 1) * 25%);
    transition: 0.5s;
    transition-delay: calc((1 - 1) * 0.05s);
    z-index: -1;
}

.DepartmentButtonStyle2
{
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: blackee00;
    transform: translateY(150%);
    border-radius: 50%;
    left: calc((2 - 1) * 25%);
    transition: 0.5s;
    transition-delay: calc((2 - 1) * 0.05s);
    z-index: -1;
}

.DepartmentButtonStyle3
{
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: blackee00;
    transform: translateY(150%);
    border-radius: 50%;
    left: calc((3 - 1) * 25%);
    transition: 0.5s;
    transition-delay: calc((3 - 1) * 0.05s);
    z-index: -1;
}

.DepartmentButtonStyle4
{
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: blackee00;
    transform: translateY(150%);
    border-radius: 50%;
    left: calc((4 - 1) * 25%);
    transition: 0.5s;
    transition-delay: calc((4 - 1) * 0.05s);
    z-index: -1;
}

.DepartmentButton:hover .DepartmentButtonStyle1{
    transform: translateY(0) scale(2);
}
.DepartmentButton:hover .DepartmentButtonStyle2{
    transform: translateY(0) scale(2);
}
.DepartmentButton:hover .DepartmentButtonStyle3{
    transform: translateY(0) scale(2);
}
.DepartmentButton:hover .DepartmentButtonStyle4{
    transform: translateY(0) scale(2);
}

.TeamWrapper{
  font-size: 0;
  border: 0;
  outline: 0;
  margin: 3%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items:center;
  animation: slide-up .3s ease;
}

.TeamSubHeading{
  display: flex;
  justify-content: center;
  align-items:center;
  color:black;
  height:fit-content;
  font-size:16px;
  padding-left:20%;
  width:60%;
  text-align: center;
}

.TeamWrapper .TeamCard{
  float: left;
  position: relative;
  height: 250px;
  width: 250px;
  overflow: hidden;
  margin: 15px;
  border-radius: 50%;
}

.TeamCard .MemberImage
{
  height: 100%;
  width: 250px;
  display: inline;
  object-fit: fill;
}

.TeamCard .MemberOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .2s ease;
  background-color: rgba(255,255,255,0.8);
}

.TeamCard .MemberName{
  opacity: 0;
  font-size: 20px;
  font-weight: 700;
  position:absolute;
  font-family:Arial, Helvetica, sans-serif;
  top: 30%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.TeamCard .MemberRank{
  color:black;
  font-size: 15px;
  position:absolute;
  font-family:Arial, Helvetica, sans-serif;
  top: 39%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.TeamCard .MemberDept{
  color:black;
  font-size: 15px;
  position:absolute;
  font-family:Arial, Helvetica, sans-serif;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.TeamCard .MemberBreak
{
  position: absolute;
  top: 64%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100px;
  height: 2px;
  border: 0;
  background-color: #000000;
}

.TeamCard .MemberIconContainer{
  position: absolute;
  height: 80px;
  width: 100px;
  top: 72%;
  left: 35%;
}

.TeamCard .MemberIcon{
  position: relative;
  height: 30px;
  width: 30px;
  margin-right: 32px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: 0.2s;
}

.MemberIcon:hover{
  height: 35px;
  width: 35px;
}

.TeamCard:hover .MemberOverlay{
  opacity: 1;
}

.TeamCard:hover .MemberName{
  color:black;
  opacity: 1;
}
