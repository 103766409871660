.ideas_full_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.ideas {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  scrollbar-width: none;
}

@media (max-width: 1024px) {
  .ideas {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    overflow: scroll;
    align-items: inherit;
  }
  .ideas::-webkit-scrollbar {
    display: none;
  }
}
@media (max-width: 1024px) {
  .ideas__division {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}

@media (max-width: 1024px) {
  .ideas__division__form {
    width: 80%;
  }
}

.ideas::-webkit-scrollbar {
  display: none;
}

.ideas__form {
  flex: 1;
  background-color: #f0f0f0;
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.ideas__form__container {
  width: 100%;
  margin-top: 65px;
}

.subheading {
  padding: 0px 90px;
}

.ideas__division {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ideas__division__image {
  flex: 0.5;
}
.ideas__division__image > h2 {
  text-align: center;
  color: #474444;
}
.ideas__division__form {
  flex: 0.5;
}
.ideas__division__form > h2 {
  text-align: center;
  color: black;
}
.ideas__division_formcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.formcontent__LI {
  height: 100%;
  width: 100%;
  margin-bottom: 25px;
}

.formcontent__LI > label {
  color: black;
  font-weight: 800;
}
input[type="text"] {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  width: 90%;
  padding: 10px;
  outline: none;
  margin: 15px 0px 20px 0px;
}
input::placeholder {
  text-align: left;
  font-family: "Poppins", sans-serif;
}
input:focus {
  border-bottom: 1px solid #eee513;
}
.ideas__model {
  display: grid;
  place-items: center;
  background-color: #474444;
  height: 70vh;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.ideas__form__container > h1 {
  text-align: center;
  color: black;
  font-size: 4em;
  text-transform: uppercase;
  letter-spacing: 2.5px;
}
@media (max-width: 1024px) {
  .ideas__form__container > h1 {
    font-size: 2.3em;
    letter-spacing: 2px;
  }

  .subheading {
    padding: 0px 20px;
  }
}
.ideas__form__container > p {
  text-align: center;
  color: #8b8b8b;
  font-weight: 500;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.1em;
}

.radioselection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  flex-wrap: wrap;
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .radioselection {
    flex-direction: column;
    align-items: normal;
  }
}

.rad-label {
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 5px 10px;
  cursor: pointer;
  transition: 0.3s;
}

.rad-label:hover,
.rad-label:focus-within {
  background: hsla(0, 0%, 80%, 0.14);
}

.rad-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.rad-design {
  width: 22px;
  height: 22px;
  border-radius: 100px;

  background: linear-gradient(
    to right bottom,
    hsl(69, 100%, 53%),
    hsl(56, 98%, 51%)
  );
  position: relative;
}

.rad-design::before {
  content: "";
  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: hsl(0, 0%, 90%);
  transform: scale(1.1);
  transition: 0.3s;
}

.rad-input:checked + .rad-design::before {
  transform: scale(0);
}

.rad-text {
  color: hsl(0, 0%, 60%);
  margin-left: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 900;
  transition: 0.3s;
}

.rad-input:checked ~ .rad-text {
  color: hsl(0, 0%, 40%);
}

.form__submit__button {
  width: 85%;
  padding: 10px;
  border-radius: 10px;
  /* box-shadow: none; */
  cursor: pointer;
  color: black;
  background-color: #ffee00;
  text-align: center;
  font-size: larger;
  font-family: "Poppins", sans-serif;
  min-width: 100px;
  outline: none;
  border: none;
}

.form__submit__button:hover {
  transition: all 0.25s ease-in;
  color: #ffee00;
  background-color: #171717;
}
