.main_card_container {
    width: 20vw;
    height: 25vw;
    overflow: hidden;
    border-radius: 1vw;
    box-shadow: 0px 3px 3.2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2vw;
    background-color:#fcfcfc;
    position: relative;
}

.main_card_container:hover .card_detail_container_2 {
    background-color:#fcfcfc;
    opacity: 1;
}
.main_card_container:hover .reponsive_image {
    opacity: 0;
}

.main_card_container:hover .card_heading{
    opacity: 0;
}


.image_card_container{
    width: 100%;
}
.reponsive_image {
    border-radius: 1vw 1vw 0vw 0vw !important;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity .2s
}

.card_detail_container {
    /*  padding: 2vw;*/
    display: flex;
    width: inherit;
    flex-direction: column;
    align-items: center;
}

.card_detail_para {
    overflow: hidden;
    margin:0;
    padding: 0.6vw;
    font-size: 1vw;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
}

.card_heading {
    color:#000;
    padding: 1.2vw;
    font-size: 1.4vw;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
}

.main_card_container .card_detail_container_2 {
    position:absolute;
    top:3vh;
    opacity: 0;
    transition: opacity .2s, display .2s;
    overflow: auto;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
}

.card_detail_container_2::-webkit-scrollbar {
    display: none;
}


@media only screen and (max-width: 1024px) {
    .main_card_container{
        width: 35vw;
        height: 40vw;
    }
    .heading_containers{
        margin-top: 5vh;
    }
    .card_heading{
        font-size: 2.8vw;
    }
    .card_detail_para{
        font-size: 2vw;
    }
    
}
@media only screen and (max-width: 550px) {
    .main_card_container{
        width: 50vw;
        height: fit-content;
    }
}
@media only screen and (max-width: 430px) {
    .main_card_container{
        width: 60vw;
        height: fit-content;
    }
    .reponsive_image{
      height:80%;
    }
    .card_heading{
        font-size: 3.7vw;
    }
    .card_detail_para{
        font-size: 3vw;
    }
}