@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;1,300;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Sulphur+Point&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Poppins', 'Inter',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html { height: auto; overflow:auto; }

:root {
  font-size: 20px;
}

::selection {
  background: #2ddab8;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  background: #0c0f13;
  font-family: "Poppins", sans-serif;
  color: #8b8b8b;
  font-size: 0.9rem;
  scroll-behavior: smooth;
  overflow:auto;
  height: auto;
}

a {
  color: #545454;
  text-decoration: none;
}

a:focus,
a:hover {
  color: #8b8b8b;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

/*.root {*/
/*  width: 100vw;*/
/*  height: 100vh;*/
/*  overflow: hidden;*/
/*}*/

.loading {
  padding: 10px;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  color: #8b8b8b;
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.frame {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1.5rem;
  z-index: 2;
}

.frame__title {
  font-size: inherit;
  font-weight: normal;
  margin: 0;
}

.frame__links {
  margin: 1rem 0;
}

.frame__link:not(:last-child) {
  margin-right: 1rem;
}

.frame__nav .frame__link:first-child {
  margin-right: 1.5rem;
}

.bottom-left {
  padding: 1.5rem;
  -webkit-transform: translate3d(0, -100%, 0) !important;
          transform: translate3d(0, -100%, 0) !important;
}

.canvas > div {
  z-index: 10000000;
}

/*#root > div > div {*/
/*  pointer-events: none;*/
/*}*/

nav {
  position: relative;
  right: 280px;
}

.line {
  height: 2px;
  position: absolute;
  bottom: 0;
  margin: 10px 0 0 0;
  background: #FF1847;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

li {
  margin: 0 40px 0 0;
  opacity: 0.4;
  transition: all 0.4s ease;
}

li:hover {
  opacity: .7;
}

li.active {
  opacity: 1;
}

li:last-child {
  margin-right: 0;
}

a {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  letter-spacing: .2em;
  font-size: 14px;
}


@media screen and (min-width: 53em) {
  body {
    overflow: hidden;
  }
  .frame {
    display: grid;
    height: 100vh;
    padding: 2rem 3.7rem;
    pointer-events: none;
    align-content: space-between;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "title links"
      "... ..."
      "... nav";
  }
  .frame__title {
    grid-area: title;
  }
  .frame__links {
    grid-area: links;
    justify-self: end;
    margin: 0;
  }
  .frame__link {
    pointer-events: auto;
  }
  .frame__nav {
    grid-area: nav;
    justify-self: end;
  }
  .bottom-left {
    padding: 0 0 2rem 3.7rem;
  }
}

.ideas_full_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.ideas {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  scrollbar-width: none;
}

@media (max-width: 1024px) {
  .ideas {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    overflow: scroll;
    align-items: inherit;
  }
  .ideas::-webkit-scrollbar {
    display: none;
  }
}
@media (max-width: 1024px) {
  .ideas__division {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}

@media (max-width: 1024px) {
  .ideas__division__form {
    width: 80%;
  }
}

.ideas::-webkit-scrollbar {
  display: none;
}

.ideas__form {
  flex: 1 1;
  background-color: #f0f0f0;
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.ideas__form__container {
  width: 100%;
  margin-top: 65px;
}

.subheading {
  padding: 0px 90px;
}

.ideas__division {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ideas__division__image {
  flex: 0.5 1;
}
.ideas__division__image > h2 {
  text-align: center;
  color: #474444;
}
.ideas__division__form {
  flex: 0.5 1;
}
.ideas__division__form > h2 {
  text-align: center;
  color: black;
}
.ideas__division_formcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.formcontent__LI {
  height: 100%;
  width: 100%;
  margin-bottom: 25px;
}

.formcontent__LI > label {
  color: black;
  font-weight: 800;
}
input[type="text"] {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  width: 90%;
  padding: 10px;
  outline: none;
  margin: 15px 0px 20px 0px;
}
input::-webkit-input-placeholder {
  text-align: left;
  font-family: "Poppins", sans-serif;
}
input:-ms-input-placeholder {
  text-align: left;
  font-family: "Poppins", sans-serif;
}
input::placeholder {
  text-align: left;
  font-family: "Poppins", sans-serif;
}
input:focus {
  border-bottom: 1px solid #eee513;
}
.ideas__model {
  display: grid;
  place-items: center;
  background-color: #474444;
  height: 70vh;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.ideas__form__container > h1 {
  text-align: center;
  color: black;
  font-size: 4em;
  text-transform: uppercase;
  letter-spacing: 2.5px;
}
@media (max-width: 1024px) {
  .ideas__form__container > h1 {
    font-size: 2.3em;
    letter-spacing: 2px;
  }

  .subheading {
    padding: 0px 20px;
  }
}
.ideas__form__container > p {
  text-align: center;
  color: #8b8b8b;
  font-weight: 500;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.1em;
}

.radioselection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  flex-wrap: wrap;
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .radioselection {
    flex-direction: column;
    align-items: normal;
  }
}

.rad-label {
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 5px 10px;
  cursor: pointer;
  transition: 0.3s;
}

.rad-label:hover,
.rad-label:focus-within {
  background: hsla(0, 0%, 80%, 0.14);
}

.rad-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.rad-design {
  width: 22px;
  height: 22px;
  border-radius: 100px;

  background: linear-gradient(
    to right bottom,
    hsl(69, 100%, 53%),
    hsl(56, 98%, 51%)
  );
  position: relative;
}

.rad-design::before {
  content: "";
  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: hsl(0, 0%, 90%);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.3s;
}

.rad-input:checked + .rad-design::before {
  -webkit-transform: scale(0);
          transform: scale(0);
}

.rad-text {
  color: hsl(0, 0%, 60%);
  margin-left: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 900;
  transition: 0.3s;
}

.rad-input:checked ~ .rad-text {
  color: hsl(0, 0%, 40%);
}

.form__submit__button {
  width: 85%;
  padding: 10px;
  border-radius: 10px;
  /* box-shadow: none; */
  cursor: pointer;
  color: black;
  background-color: #ffee00;
  text-align: center;
  font-size: larger;
  font-family: "Poppins", sans-serif;
  min-width: 100px;
  outline: none;
  border: none;
}

.form__submit__button:hover {
  transition: all 0.25s ease-in;
  color: #ffee00;
  background-color: #171717;
}

.model {
    height: 100%;
    width: 100%;
    background-image: url("https://wallpaperaccess.com/full/1140482.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    min-width: 300px;
}
.model {

}

.scrollClicker {
    position: absolute;
    width: 250px;
    top: 300px;
    right: -160px;
    cursor: pointer;
    display: flex;
}

@media (max-width: 968px) {
    .scrollClicker {
        top: 200px;
        right: -150px;
    }
}

.box p {
    color: hsl(229, 6%, 66%);
}


.subheading
{
  display: flex;
  flex-direction:column;
  text-align: center;
  vertical-align: top;
  padding-left: 20%;
  padding-right: 20%;
}


.heading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: black;
    font-size: 1.6em;
    margin-bottom: 0;
}

.box {
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px hsl(229, 6%, 66%);
    padding: 30px;
    margin: 20px;
}

img {
    float: right;
}

@media (max-width: 450px) {
    .box {
        height: 200px;
    }
}

@media (max-width: 950px) and (min-width: 450px) {
    .box {
        text-align: center;
        height: 180px;
    }
}

.cyan {
    border-top: 3px solid hsl(180, 62%, 55%);
}
.red {
    border-top: 3px solid hsl(0, 78%, 62%);
}
.blue {
    border-top: 3px solid hsl(212, 86%, 64%);
}
.orange {
    border-top: 3px solid hsl(34, 97%, 64%);
}

h2 {
    color: hsl(234, 12%, 34%);
    font-weight: 600;
}


@media (min-width: 950px) {
    .row1-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .row2-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .box-down {
        position: relative;
        top: 150px;
    }
    .box {
        width: 20%;
    }
    .header p {
        width: 30%;
    }

}

.Ourteam_Main__35u1g{
  justify-content: center;
  align-items:center;
  width:100%;
  overflow: hidden;
  background-color: #f0f0f0;
}

.Ourteam_TitleContainer__HsYmD{
  color:black;
  display: flex;
  flex-direction:column;
  margin-top: 3rem;
  text-align: center;
  height:10%;
  padding: 10px 25px;
  font-size: 40px;
  margin-bottom: 3rem;
}

.Ourteam_Title__I17i- {
    opacity: 1;
    color:black;
    display: flex;
    flex-direction:column;
    padding: 10px 25px;
    font-size: larger;
    width: 60%;
    align-self: center;
}


.Ourteam_Slider__3Yfcs{
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction:column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height:50vh;
  width: 100%;
  transition: all 0.3s ease;
}

.Ourteam_Slider__3Yfcs .Ourteam_SliderButtonleft__3pxtb{
  position:absolute;
  top:40%;
  left:3%;
  background-color: rgba(255,255,255,0.5);
  border: none;
  color: black;
  padding: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  height:25px;
  width:25px;
  border-radius: 50%;
  cursor: pointer;
}

.Ourteam_Slider__3Yfcs .Ourteam_SliderButtonright__1Y0v0{
  position:absolute;
  top:40%;
  right:3%;
  background-color: rgba(255,255,255,0.5);
  border: none;
  color: black;
  padding: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  height:25px;
  width:25px;
  border-radius: 50%;
  cursor: pointer;
}

.Ourteam_SliderButtonright__1Y0v0:hover{
  background-color: rgba(255,255,255,0.8);
}

.Ourteam_SliderButtonleft__3pxtb:hover{
  background-color: rgba(255,255,255,0.8);
}


.Ourteam_subheading__3JDuU
{
  display: flex;
  flex-direction:column;
  color: #8b8b8b;
  text-align: center;
  vertical-align: top;
  padding-left: 20%;
  padding-right: 20%;
}


.Ourteam_heading__XluKr {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #8b8b8b;
    font-size: 1.6em;
    margin-bottom: 0;
}

.Ourteam_DepartmentButtonContainer__3vDKI{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items:center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  max-height: 20%;
  font-size: 40px;
}

.Ourteam_DepartmentButton__1pxj-{
    color:black;
    overflow: hidden;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction:column;
    font-weight: bold;
    border: 5px solid #eee513;
    border-radius: 15px;
    padding: 5px 5px;
    min-width: 30%;
    transition: .1s ease;
}

.Ourteam_DepartmentButton__1pxj-:hover{
    color:black;
}

.Ourteam_DepartmentButtonStyle1__4qows
{
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: blackee00;
    -webkit-transform: translateY(150%);
            transform: translateY(150%);
    border-radius: 50%;
    left: calc((1 - 1) * 25%);
    transition: 0.5s;
    transition-delay: calc((1 - 1) * 0.05s);
    z-index: -1;
}

.Ourteam_DepartmentButtonStyle2__2qVUZ
{
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: blackee00;
    -webkit-transform: translateY(150%);
            transform: translateY(150%);
    border-radius: 50%;
    left: calc((2 - 1) * 25%);
    transition: 0.5s;
    transition-delay: calc((2 - 1) * 0.05s);
    z-index: -1;
}

.Ourteam_DepartmentButtonStyle3__2n5C6
{
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: blackee00;
    -webkit-transform: translateY(150%);
            transform: translateY(150%);
    border-radius: 50%;
    left: calc((3 - 1) * 25%);
    transition: 0.5s;
    transition-delay: calc((3 - 1) * 0.05s);
    z-index: -1;
}

.Ourteam_DepartmentButtonStyle4__30uCh
{
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: blackee00;
    -webkit-transform: translateY(150%);
            transform: translateY(150%);
    border-radius: 50%;
    left: calc((4 - 1) * 25%);
    transition: 0.5s;
    transition-delay: calc((4 - 1) * 0.05s);
    z-index: -1;
}

.Ourteam_DepartmentButton__1pxj-:hover .Ourteam_DepartmentButtonStyle1__4qows{
    -webkit-transform: translateY(0) scale(2);
            transform: translateY(0) scale(2);
}
.Ourteam_DepartmentButton__1pxj-:hover .Ourteam_DepartmentButtonStyle2__2qVUZ{
    -webkit-transform: translateY(0) scale(2);
            transform: translateY(0) scale(2);
}
.Ourteam_DepartmentButton__1pxj-:hover .Ourteam_DepartmentButtonStyle3__2n5C6{
    -webkit-transform: translateY(0) scale(2);
            transform: translateY(0) scale(2);
}
.Ourteam_DepartmentButton__1pxj-:hover .Ourteam_DepartmentButtonStyle4__30uCh{
    -webkit-transform: translateY(0) scale(2);
            transform: translateY(0) scale(2);
}

.Ourteam_TeamWrapper__3KrXc{
  font-size: 0;
  border: 0;
  outline: 0;
  margin: 3%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items:center;
  -webkit-animation: Ourteam_slide-up__1IM1- .3s ease;
          animation: Ourteam_slide-up__1IM1- .3s ease;
}

.Ourteam_TeamSubHeading__3GPMi{
  display: flex;
  justify-content: center;
  align-items:center;
  color:black;
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content;
  font-size:16px;
  padding-left:20%;
  width:60%;
  text-align: center;
}

.Ourteam_TeamWrapper__3KrXc .Ourteam_TeamCard__mbZ_z{
  float: left;
  position: relative;
  height: 250px;
  width: 250px;
  overflow: hidden;
  margin: 15px;
  border-radius: 50%;
}

.Ourteam_TeamCard__mbZ_z .Ourteam_MemberImage__2aJyf
{
  height: 100%;
  width: 250px;
  display: inline;
  object-fit: fill;
}

.Ourteam_TeamCard__mbZ_z .Ourteam_MemberOverlay__1fSbI {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .2s ease;
  background-color: rgba(255,255,255,0.8);
}

.Ourteam_TeamCard__mbZ_z .Ourteam_MemberName__3edjl{
  opacity: 0;
  font-size: 20px;
  font-weight: 700;
  position:absolute;
  font-family:Arial, Helvetica, sans-serif;
  top: 30%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.Ourteam_TeamCard__mbZ_z .Ourteam_MemberRank__1FcX7{
  color:black;
  font-size: 15px;
  position:absolute;
  font-family:Arial, Helvetica, sans-serif;
  top: 39%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.Ourteam_TeamCard__mbZ_z .Ourteam_MemberDept__104nu{
  color:black;
  font-size: 15px;
  position:absolute;
  font-family:Arial, Helvetica, sans-serif;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.Ourteam_TeamCard__mbZ_z .Ourteam_MemberBreak__KecNQ
{
  position: absolute;
  top: 64%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100px;
  height: 2px;
  border: 0;
  background-color: #000000;
}

.Ourteam_TeamCard__mbZ_z .Ourteam_MemberIconContainer__1ryOU{
  position: absolute;
  height: 80px;
  width: 100px;
  top: 72%;
  left: 35%;
}

.Ourteam_TeamCard__mbZ_z .Ourteam_MemberIcon__2sA4d{
  position: relative;
  height: 30px;
  width: 30px;
  margin-right: 32px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: 0.2s;
}

.Ourteam_MemberIcon__2sA4d:hover{
  height: 35px;
  width: 35px;
}

.Ourteam_TeamCard__mbZ_z:hover .Ourteam_MemberOverlay__1fSbI{
  opacity: 1;
}

.Ourteam_TeamCard__mbZ_z:hover .Ourteam_MemberName__3edjl{
  color:black;
  opacity: 1;
}

.recruitment__page {
  display: flex;
  flex-direction: column;
  height: 200vh;
  background: white;
}

.recruitment__info__div {
  display: flex;
  flex: 0.25 1;
  flex-direction: column;
  margin: 60px 10px 50px 10px;
  align-items: center;
}

.recruitment__info__header {
  text-transform: capitalize;
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 3em;
  font-family: "Poppins", sans-serif;
}

.recruitment__info {
  color: #8b8b8b;
  font-family: "Poppins", sans-serif;
  font-size: 1.2em;
  font-weight: 400;
  margin: 0px 200px;
  text-align: center;
}

.recruitment__form__div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.70 1;
  margin: 30px 0px;
}

@media (max-width: 720px) {
  .recruitment__form__div > iframe {
    min-width: 90%;
  }
}
@media (max-width: 720px) {
  .recruitment__info {
    font-weight: 300;
    font-size: medium;
    width: 90%
  }
}
@media (max-width: 720px) {
  .recruitment__info__header {
    font-size: 2.3em;
  }
}

.recruitment__form__soon {
  color: black;
  font-size: xx-large;
  display: flex;
  flex-direction: column;
  margin: 50px;
}

.header {
  display: flex;
  height: 9vh;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  box-sizing: border-box;
  z-index: 99999999999999;
  background: inherit;
  padding: 0 15px;

  box-shadow: inset 0 0 2000px rgba(25, 25, 25, 0.9);
}

.recruit_cta {
  margin-right: 20px;
}

@media (min-width: 798px) {
  .header {
    padding: 0 35px;
  }
}

.header:before {
  filter: blur(20px);
  -webkit-filter: blur(20px);
  background: inherit;
}

.logo {
  color: white;
}

.navigation__items {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0;
  justify-content: space-evenly;
  margin: 0;
  flex: 0.6 1;
}

.navigation__item {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  flex: 1 1;
  padding: 0px 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  z-index: 99999999991;
}

.navigation__item > a {
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  text-align: center;
  color: #ffee00;
  padding: 0px 10px;
}
.navigation__item > a:hover {
  color: #282727;
  background-color: #ffee00;
  transition: all 0.25s ease-in;
}

.logo {
  display: flex;
  height: 100%;
  align-items: center;
  flex: 0.075 1;
  justify-content: center;
}

.logo > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0px 10px;
}

.logo > a > img {
  height: 80%;
}

.cta_button {
  background: transparent;
  color: #ffee00;
  border: 2px solid #ffee00;
  padding: 5px 20px;
  font-size: 18px;
  border-radius: 5em;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.cta_button:hover {
  cursor: pointer;
  color: black;
  background: #ffee00;
}

.recruit_cta {
  margin-right: 40px;
}

@media (max-width: 720px) {
  .navigation__items {
    display: none;
  }

  .recruit_cta {
    display: none;
  }
}

@media (max-width: 720px) {
  .header {
    flex-direction: row-reverse;
  }
}

@media (min-width: 720px) {
    .hamburger {
        display: none;
    }
}
@media (max-width: 720px) {

    .hamburger {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 10px;
        cursor: pointer;

    }
    .hamburger > img {
        height: 70%;
    }
}

.heading_containers {
  display: flex;
  justify-content: center;
  /*padding: 1.5vw;*/
  width: 100%;
  margin: 25px 0px;
}

.heading_container {
  display: flex;
  justify-content: center;
  margin-top: 9vh;
  width: 100%;
}

#Events_first_container {
  background-color: white !important;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#Main_Item_Container {
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  justify-content: center;
}

.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 35px 0px;
}

.main_card_container {
  /* width: 30vw;
    height: 25vw;
    overflow: hidden;
    border-radius: 1vw;
    box-shadow: 0px 0px 3.2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2vw; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30vw;
  border: 1px solid black;
}

/* .main_card_container:hover .card_detail_container_2 {
    background-color: rgba(255, 255, 255, 0.514);
    opacity: 1;
}
.main_card_container:hover .reponsive_image {
    opacity: 0;
} */
.image_container {
  width: 100%;
  flex: 0.5 1;
  height: 100%;
}
.reponsive_image {
  border-radius: 1vw 1vw 0vw 0vw !important;
  width: 100%;
  width: 100%;
  height: auto;
  border-radius: 2vw;
  transition: opacity 0.2s;
}

.card_detail_container {
  flex: 0.5 1;
  padding: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.card_detail_description {
  overflow: hidden;
  padding: 2.2vw;
  font-size: 1.3vw;
  color: #8b8b8b;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  white-space: pre-wrap;
}

.card_detail_title {
  font-size: 2vw;
  font-style: normal;
  font-weight: normal;
}

.card_detail_container_2 {
  position: relative;
  bottom: 26vw;
  max-height: 20vw;
  /* overflow: scroll; */
  opacity: 0;
  transition: opacity 0.2s, display 0.2s;
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

.card_detail_container_2::-webkit-scrollbar {
  display: none;
}

#Heading {
  font-family: "Poppins", sans-serif;
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 4em;
  text-align: center;
  margin-top: 1em;
}

#Heading_para {
  font-size: 1.5vw;
  margin: 5px 6em;
  color: #8b8b8b;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.separator_container {
  width: 100%;
  font-family: "Poppins", sans-serif;
  color: rgb(25, 26, 22);
  font-size: 6vh;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2vw;
}

.separator_container::before {
  content: "";
  display: block;
  height: 0.09em;
  min-width: 30vw;
  background: linear-gradient(
    to right,
    rgba(240, 240, 240, 0),
    rgb(237 255 89)
  );
  margin-right: 4vh;
}

.separator_container::after {
  content: "";
  display: block;
  height: 0.09em;
  min-width: 30vw;
  background: linear-gradient(to left, rgba(240, 240, 240, 0), rgb(237 255 89));
  margin-left: 4vh;
}

@media only screen and (max-width: 1024px) {
  #Heading {
    font-size: 2.5em;
  }
  #Heading_para {
    font-size: 2.8vw;
    margin: 5px 3em;
  }
  .main_card_container {
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 200px;
  }
  .heading_containers {
    margin-top: 5px;
  }
  /* .card_heading {
    font-size: 2.8vw;
  } */
  /* card_detail_container_2 {
    bottom: 30vw;
  } */
  .card_detail_description {
    font-size: 2vw;
  }
}
@media only screen and (max-width: 550px) {
  /*#Heading{*/
  /*    font-size: 7.7vw !important;*/
  /*}*/
  #Heading_para {
    font-size: 2.5vw;
  }
  .main_card_container {
    width: 70%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:auto;
  }
  .card_detail_description {
    font-size: 2vw;
  }
  .separator_container {
    font-size: 5vh;
    padding: 1vh;
    margin-right: 30px;
    display:none;
  }
}
@media only screen and (max-width: 430px) {
  #Heading_para {
    font-size: 0.9em;
  }
  .main_card_container {
    width: 50%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:auto;
  }
  .card_detail_title {
    font-size: 3.7vw;
  }
  .card_detail_container_2 {
    bottom: 37vw;
  }
  .card_detail_description{
    font-size: 2.7vw;
  }
  .separator_container {
    font-size: 4vh;
    padding: 1vh;
    margin-right: 30px;
    display: none;
  }
}

.Card_main_card_container__11T3X {
    width: 20vw;
    height: 25vw;
    overflow: hidden;
    border-radius: 1vw;
    box-shadow: 0px 3px 3.2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2vw;
    background-color:#fcfcfc;
    position: relative;
}

.Card_main_card_container__11T3X:hover .Card_card_detail_container_2__2Yerb {
    background-color:#fcfcfc;
    opacity: 1;
}
.Card_main_card_container__11T3X:hover .Card_reponsive_image__AUEqa {
    opacity: 0;
}

.Card_main_card_container__11T3X:hover .Card_card_heading__35Brs{
    opacity: 0;
}


.Card_image_card_container__ly16V{
    width: 100%;
}
.Card_reponsive_image__AUEqa {
    border-radius: 1vw 1vw 0vw 0vw !important;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity .2s
}

.Card_card_detail_container__2mnjh {
    /*  padding: 2vw;*/
    display: flex;
    width: inherit;
    flex-direction: column;
    align-items: center;
}

.Card_card_detail_para__3rdAc {
    overflow: hidden;
    margin:0;
    padding: 0.6vw;
    font-size: 1vw;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
}

.Card_card_heading__35Brs {
    color:#000;
    padding: 1.2vw;
    font-size: 1.4vw;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
}

.Card_main_card_container__11T3X .Card_card_detail_container_2__2Yerb {
    position:absolute;
    top:3vh;
    opacity: 0;
    transition: opacity .2s, display .2s;
    overflow: auto;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
}

.Card_card_detail_container_2__2Yerb::-webkit-scrollbar {
    display: none;
}


@media only screen and (max-width: 1024px) {
    .Card_main_card_container__11T3X{
        width: 35vw;
        height: 40vw;
    }
    .Card_heading_containers__1_yjQ{
        margin-top: 5vh;
    }
    .Card_card_heading__35Brs{
        font-size: 2.8vw;
    }
    .Card_card_detail_para__3rdAc{
        font-size: 2vw;
    }
    
}
@media only screen and (max-width: 550px) {
    .Card_main_card_container__11T3X{
        width: 50vw;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
}
@media only screen and (max-width: 430px) {
    .Card_main_card_container__11T3X{
        width: 60vw;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .Card_reponsive_image__AUEqa{
      height:80%;
    }
    .Card_card_heading__35Brs{
        font-size: 3.7vw;
    }
    .Card_card_detail_para__3rdAc{
        font-size: 3vw;
    }
}
.sidedrawer {
  position: fixed;
  width: 280px;
  max-width: 70%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999999999999999;
  /*background-color: #ede75a;*/
  padding: 32px 16px;
  box-sizing: border-box;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: rgba(21, 19, 19, 0.65);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18); */

  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
  background-color: rgba(224, 233, 233, 0.89);
  border: 1px solid rgba(255, 255, 255, 0.125);

}

@media (min-width: 720px) {
  .sidedrawer {
    display: none;
  }
}

.open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.close {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.sidedrawer .sidedrawer__name {
  margin-top:10px;
  font-family: "Poppins", sans-serif;
  padding:0;
  border:0;
  align-items: center;
  justify-content: center;
  width: 100%;
  color:#282727;
}

.sidedrawer .sidedrawer__links {
  text-transform: none;
  font-family: "Poppins", sans-serif;
  font-size:15px;
  letter-spacing: 0px;
  height:9vh;
  margin:0;
  border:0;
  padding:0;
  width: 100%;
  color:#282727;
  font-weight: bold;
}

.sidedrawer .sidedrawer__back{
  height:50px;
  width:50px;
  left:2vw;
  top:2vh;
  position: absolute;
  cursor: pointer;
}

.Backdrop {
    width:100%;
    height:100%;
    position:fixed;
    z-index: 200;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
@media only screen and (max-width: 1024px) {
  .Gallery_mainimage__5Ude_{
    height: 100vh;
    margin-bottom: -30rem;
  }

  .Gallery_headerContainer__zBxzI{
    max-width: 100%;
    left:0;
  }

  .Gallery_TitleContainer__oTmi0{
    margin: 0;
    text-align: center;
    margin:auto;
  }
}

.Gallery_headerContainer__zBxzI{
  position:"relative";left:"10vw";max-width:"50%";margin: bottom -10rem;
}

.Gallery_mainimage__5Ude_{
  position:"relative";
  object-fit:"cover";
  top:0;
  left:0;
  height:"70vh";
  width:"100vw";
  margin-bottom:"-16rem";
}

.Gallery_Main__2JBzf{
  background-color: #fff;
  left:0;
  display: flex;
  flex-direction:column;
  overflow: hidden;
}

.Gallery_Slider__3KV_u{
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction:column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height:50vh;
  width: 100%;
  transition: all 0.3s ease;
}


.Gallery_TitleContainer__oTmi0{
  position: relative;
  font-family: "Poppins", sans-serif;
  margin-bottom:2rem;
}

.Gallery_Title__vdpCP {
    color:#000;
    display: flex;
    flex-direction:column;
    width: 60%;
    align-self: center; 
    border: 6px solid #000000;
    padding: 10px 25px;
}

/*.Title {
    color:#fff;
    display: flex;
    flex-direction:column;
    border: 3px solid #eee513;
    padding: 10px 25px;
    width: 60%;
    align-self: center; 
}*/

.Gallery_subheading__8Vx8r
{
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction:column;
  color:#8b8b8b;
  text-align: center;
  vertical-align: top;
  padding-left: 20%;
  padding-right: 20%;
}



.Gallery_TeamWrapper__a8mKz{
  font-size: 0;
  border: 0;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items:center;
  flex-wrap: wrap;
  margin: 2rem;
}

.Gallery_TeamWrapper__a8mKz .Gallery_TeamCard__hiuC6{
  float:left;
  margin:6px;
  position: relative;
}

.Gallery_TeamCard__hiuC6 .Gallery_MemberImage__1m607
{
  height: 260px;
  width: 320px;
  object-fit: fill;
}

.Gallery_TeamCard__hiuC6 .Gallery_MemberOverlay__8tBm6 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .2s ease;
  background-color: rgba(255,255,255,0.8);
}

.Gallery_TeamCard__hiuC6 .Gallery_MemberRank__U3Vnv{
  color:black;
  font-size: 14px;
  position:absolute;
  font-family:'Poppins', sans-serif;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}


.Gallery_TeamCard__hiuC6:hover .Gallery_MemberOverlay__8tBm6{
  opacity: 1;
}

@media only screen and (max-width: 1024px) {
  .Gallery_TitleContainer__oTmi0{
    font-size:40px;
  }
}


@media only screen and (max-width: 600px) {
  .Gallery_TitleContainer__oTmi0{
    font-size:30px;
  }
}
.Project_container {
  top: 4vw;
  background-color: #f0f0f0;
}

#FIRST {
  height: 200vw;
  flex-wrap: wrap;
}

.panel_projects {
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.nav-link {
  color: black;
  text-decoration: none;
  margin: 0.4vw;
  font-family: Carrois Gothic SC;
  font-style: normal;
  font-weight: normal;
}

.projects_main_card_container:hover .projects_card_detail_container {
  opacity: 1;
}

#Projects_Main_Item_Container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.projects_main_card_container {
  width: 31vw;
  border: 1px black solid;
  margin: 0.9vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projects_image_card_container {
  width: 100%;
}

.projects_reponsive_image {
  width: 100%;
}

.projects_card_detail_container {
  position: absolute;
  overflow: hidden;
  height: 46vw;
  width: inherit;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  opacity: 0;
  transition: opacity .2s, display .2s
}

.projects_card_detail_para {
  padding: 2vw;
  font-size: 1vw;
  font-family: Poppins;
}

.Projects_description_para {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: inherit;
  align-items: center;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE 11 */
  scrollbar-width: none;
  /* Firefox 64 */
}

.Projects_description_para::-webkit-scrollbar {
  display: none;
}

.projects_card_heading {
  font-size: 1.4vw;
  font-family: Poppins;
}

@media screen and (max-width:800px) {
  #lottie {
    width: 103vw !important;
  }
  .Project_container {
    top: 14vw;
  }
  #Main_page_heading {
    font-size: 6.5vw !important;
  }
  #FIRST {
    height: 1350vw !important;
  }
  .projects_main_card_container {
    width: 74vw !important;
  }
  .projects_card_detail_container {
    height: 105vw !important;
  }
  .projects_card_heading {
    font-size: 4.1vw !important;
  }
  .projects_card_detail_para {
    font-size: 3vw;
  }
}

@media screen and (max-width:940px) and (min-width:800px) {
  #lottie {
    width: 83vw !important;
  }
  .Project_container {
    top: 10vw;
  }
  #Main_page_heading {
    font-size: 6vw !important;
  }
  #FIRST {
    height: 450vw !important;
  }
  .projects_main_card_container {
    width: 48vw !important;
  }
  .projects_card_detail_container {
    height: 69vw !important;
  }
  .projects_card_heading {
    font-size: 3.7vw !important;
  }
  .projects_card_detail_para {
    font-size: 2.5vw;
  }
}

@media screen and (max-width:1070px) and (min-width:940px) {
  #lottie {
    width: 83vw !important;
  }
  .Project_container {
    top: 5vw;
  }
  #Main_page_heading {
    font-size: 6vw !important;
  }
  #FIRST {
    height: 450vw !important;
  }
  .projects_main_card_container {
    width: 48vw !important;
  }
  .projects_card_detail_container {
    height: 68vw !important;
  }
  .projects_card_heading {
    font-size: 3.7vw !important;
  }
  .projects_card_detail_para {
    font-size: 2.5vw;
  }
}

@media screen and (max-width:1302px) and (min-width:1070px) {
  #lottie {
    width: 75vw !important;
  }
  .Project_container {
    top: 4vw;
  }
  #Main_page_heading {
    font-size: 5vw !important;
    margin: 0px !important;
  }
  #FIRST {
    height: 380vw !important;
  }
  .projects_main_card_container {
    width: 40vw !important;
  }
  .projects_card_detail_container {
    height: 60vw !important;
  }
  .projects_card_heading {
    font-size: 3vw !important;
  }
  .projects_card_detail_para {
    font-size: 2vw;
  }
}

@media screen and (max-width:1474px) and (min-width:1302px) {
  #lottie {
    width: 70vw !important;
  }
  .Project_container {
    top: 5vw;
  }
  #Main_page_heading {
    font-size: 4vw !important;
    margin: 0px !important;
  }
  #FIRST {
    height: 190vw !important;
  }
  .projects_main_card_container {
    width: 30vw !important;
  }
  .projects_card_detail_container {
    height: 43vw !important;
  }
  .projects_card_heading {
    font-size: 2vw !important;
  }
  .projects_card_detail_para {
    font-size: 1.5vw;
  }
}
.alumini_Main__3-dW7{
    justify-content: center;
    align-items:center;
    width:100%;
    overflow: hidden;
    background-color: white;
  }
  
  .alumini_TitleContainer__2RXWA{
    color:black;
    display: flex;
    flex-direction:column;
    margin-top: 3rem;
    text-align: center;
    height:10%;
    padding: 10px 25px;
    font-size: 40px;
    margin-bottom: 3rem;
  }
  
  .alumini_Title__cJrBZ {
      color:black;
      display: flex;
      flex-direction:column;
      border: 6px solid black;
      padding: 10px 25px;
      font-size: larger;
      width: 60%;
      align-self: center;
  }
  
  
  .alumini_Slider__1YpVN{
    overflow: hidden;
    display: flex;
    position: relative;
    flex-direction:column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height:50vh;
    width: 100%;
    transition: all 0.3s ease;
  }
  
  .alumini_Slider__1YpVN .alumini_SliderButtonleft__33m2J{
    position:absolute;
    top:40%;
    left:3%;
    background-color: rgba(255,255,255,0.5);
    border: none;
    color: black;
    padding: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    height:25px;
    width:25px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .alumini_Slider__1YpVN .alumini_SliderButtonright__2Sj8V{
    position:absolute;
    top:40%;
    right:3%;
    background-color: rgba(255,255,255,0.5);
    border: none;
    color: black;
    padding: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    height:25px;
    width:25px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .alumini_SliderButtonright__2Sj8V:hover{
    background-color: rgba(255,255,255,0.8);
  }
  
  .alumini_SliderButtonleft__33m2J:hover{
    background-color: rgba(255,255,255,0.8);
  }
  
  
  .alumini_subheading__2eHbX
  {
    display: flex;
    flex-direction:column;
    color: #8b8b8b;
    text-align: center;
    vertical-align: top;
    padding-left: 20%;
    padding-right: 20%;
  }
  
  
  .alumini_heading__y0-SF {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: #8b8b8b;
      font-size: 1.6em;
      margin-bottom: 0;
  }
  
  .alumini_DepartmentButtonContainer__3Cbld{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items:center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    max-height: 20%;
    font-size: 40px;
  }
  
  .alumini_DepartmentButton__abcWo{
      color:black;
      overflow: hidden;
      text-align: center;
      position: relative;
      display: flex;
      flex-direction:column;
      font-weight: bold;
      border: 5px solid #eee513;
      border-radius: 15px;
      padding: 5px 5px;
      min-width: 30%;
      transition: .1s ease;
  }
  
  .alumini_DepartmentButton__abcWo:hover{
      color:black;
  }
  
  .alumini_DepartmentButtonStyle1__2Qbi5
  {
      position: absolute;
      width: 25%;
      height: 100%;
      background-color: blackee00;
      -webkit-transform: translateY(150%);
              transform: translateY(150%);
      border-radius: 50%;
      left: calc((1 - 1) * 25%);
      transition: 0.5s;
      transition-delay: calc((1 - 1) * 0.05s);
      z-index: -1;
  }
  
  .alumini_DepartmentButtonStyle2__SMTWL
  {
      position: absolute;
      width: 25%;
      height: 100%;
      background-color: blackee00;
      -webkit-transform: translateY(150%);
              transform: translateY(150%);
      border-radius: 50%;
      left: calc((2 - 1) * 25%);
      transition: 0.5s;
      transition-delay: calc((2 - 1) * 0.05s);
      z-index: -1;
  }
  
  .alumini_DepartmentButtonStyle3__g3QSp
  {
      position: absolute;
      width: 25%;
      height: 100%;
      background-color: blackee00;
      -webkit-transform: translateY(150%);
              transform: translateY(150%);
      border-radius: 50%;
      left: calc((3 - 1) * 25%);
      transition: 0.5s;
      transition-delay: calc((3 - 1) * 0.05s);
      z-index: -1;
  }
  
  .alumini_DepartmentButtonStyle4__3VaZC
  {
      position: absolute;
      width: 25%;
      height: 100%;
      background-color: blackee00;
      -webkit-transform: translateY(150%);
              transform: translateY(150%);
      border-radius: 50%;
      left: calc((4 - 1) * 25%);
      transition: 0.5s;
      transition-delay: calc((4 - 1) * 0.05s);
      z-index: -1;
  }
  
  .alumini_DepartmentButton__abcWo:hover .alumini_DepartmentButtonStyle1__2Qbi5{
      -webkit-transform: translateY(0) scale(2);
              transform: translateY(0) scale(2);
  }
  .alumini_DepartmentButton__abcWo:hover .alumini_DepartmentButtonStyle2__SMTWL{
      -webkit-transform: translateY(0) scale(2);
              transform: translateY(0) scale(2);
  }
  .alumini_DepartmentButton__abcWo:hover .alumini_DepartmentButtonStyle3__g3QSp{
      -webkit-transform: translateY(0) scale(2);
              transform: translateY(0) scale(2);
  }
  .alumini_DepartmentButton__abcWo:hover .alumini_DepartmentButtonStyle4__3VaZC{
      -webkit-transform: translateY(0) scale(2);
              transform: translateY(0) scale(2);
  }
  
  .alumini_TeamWrapper__2_JMc{
    font-size: 0;
    border: 0;
    outline: 0;
    margin: 5%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items:center;
    -webkit-animation: alumini_slide-up__1N31j .3s ease;
            animation: alumini_slide-up__1N31j .3s ease;
  }
  
  .alumini_TeamSubHeading__17n1o{
    display: flex;
    justify-content: center;
    align-items:center;
    color:black;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    font-size:16px;
    padding-left:20%;
    width:60%;
    text-align: center;
  }
  
  .alumini_TeamWrapper__2_JMc .alumini_TeamCard__28oXM{
    float: left;
    position: relative;
    height: 250px;
    width: 250px;
    overflow: hidden;
    margin: 15px;
    border-radius: 50%;
  }
  
  .alumini_TeamCard__28oXM .alumini_MemberImage__3BGsE
  {
    height: 100%;
    width: 250px;
    display: inline;
    object-fit: fill;
  }
  
  .alumini_TeamCard__28oXM .alumini_MemberOverlay__1gLY7 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .2s ease;
    background-color: rgba(255,255,255,0.8);
  }
  
  .alumini_TeamCard__28oXM .alumini_MemberName__3UL85{
    opacity: 0;
    font-size: 20px;
    font-weight: 700;
    position:absolute;
    font-family:Arial, Helvetica, sans-serif;
    top: 30%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .alumini_TeamCard__28oXM .alumini_MemberRank__1XfBg{
    color:black;
    font-size: 15px;
    position:absolute;
    font-family:Arial, Helvetica, sans-serif;
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .alumini_TeamCard__28oXM .alumini_MemberDept__oBUHQ{
    color:black;
    font-size: 15px;
    position:absolute;
    font-family:Arial, Helvetica, sans-serif;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .alumini_TeamCard__28oXM .alumini_MemberBreak__nRBYU
  {
    position: absolute;
    top: 64%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100px;
    height: 2px;
    border: 0;
    background-color: #000000;
  }
  
  .alumini_TeamCard__28oXM .alumini_MemberIconContainer__2c7fj{
    position: absolute;
    height: 80px;
    width: 100px;
    top: 72%;
    left: 35%;
  }
  
  .alumini_TeamCard__28oXM .alumini_MemberIcon__306Xm{
    position: relative;
    height: 30px;
    width: 30px;
    margin-right: 32px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: 0.2s;
  }
  
  .alumini_MemberIcon__306Xm:hover{
    height: 35px;
    width: 35px;
  }
  
  .alumini_TeamCard__28oXM:hover .alumini_MemberOverlay__1gLY7{
    opacity: 1;
  }
  
  .alumini_TeamCard__28oXM:hover .alumini_MemberName__3UL85{
    color:black;
    opacity: 1;
  }
  
