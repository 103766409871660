.heading_containers {
  display: flex;
  justify-content: center;
  /*padding: 1.5vw;*/
  width: 100%;
  margin: 25px 0px;
}

.heading_container {
  display: flex;
  justify-content: center;
  margin-top: 9vh;
  width: 100%;
}

#Events_first_container {
  background-color: white !important;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#Main_Item_Container {
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  justify-content: center;
}

.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 35px 0px;
}

.main_card_container {
  /* width: 30vw;
    height: 25vw;
    overflow: hidden;
    border-radius: 1vw;
    box-shadow: 0px 0px 3.2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2vw; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30vw;
  border: 1px solid black;
}

/* .main_card_container:hover .card_detail_container_2 {
    background-color: rgba(255, 255, 255, 0.514);
    opacity: 1;
}
.main_card_container:hover .reponsive_image {
    opacity: 0;
} */
.image_container {
  width: 100%;
  flex: 0.5;
  height: 100%;
}
.reponsive_image {
  border-radius: 1vw 1vw 0vw 0vw !important;
  width: 100%;
  width: 100%;
  height: auto;
  border-radius: 2vw;
  transition: opacity 0.2s;
}

.card_detail_container {
  flex: 0.5;
  padding: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.card_detail_description {
  overflow: hidden;
  padding: 2.2vw;
  font-size: 1.3vw;
  color: #8b8b8b;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  white-space: pre-wrap;
}

.card_detail_title {
  font-size: 2vw;
  font-style: normal;
  font-weight: normal;
}

.card_detail_container_2 {
  position: relative;
  bottom: 26vw;
  max-height: 20vw;
  /* overflow: scroll; */
  opacity: 0;
  transition: opacity 0.2s, display 0.2s;
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

.card_detail_container_2::-webkit-scrollbar {
  display: none;
}

#Heading {
  font-family: "Poppins", sans-serif;
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 4em;
  text-align: center;
  margin-top: 1em;
}

#Heading_para {
  font-size: 1.5vw;
  margin: 5px 6em;
  color: #8b8b8b;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.separator_container {
  width: 100%;
  font-family: "Poppins", sans-serif;
  color: rgb(25, 26, 22);
  font-size: 6vh;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2vw;
}

.separator_container::before {
  content: "";
  display: block;
  height: 0.09em;
  min-width: 30vw;
  background: linear-gradient(
    to right,
    rgba(240, 240, 240, 0),
    rgb(237 255 89)
  );
  margin-right: 4vh;
}

.separator_container::after {
  content: "";
  display: block;
  height: 0.09em;
  min-width: 30vw;
  background: linear-gradient(to left, rgba(240, 240, 240, 0), rgb(237 255 89));
  margin-left: 4vh;
}

@media only screen and (max-width: 1024px) {
  #Heading {
    font-size: 2.5em;
  }
  #Heading_para {
    font-size: 2.8vw;
    margin: 5px 3em;
  }
  .main_card_container {
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 200px;
  }
  .heading_containers {
    margin-top: 5px;
  }
  /* .card_heading {
    font-size: 2.8vw;
  } */
  /* card_detail_container_2 {
    bottom: 30vw;
  } */
  .card_detail_description {
    font-size: 2vw;
  }
}
@media only screen and (max-width: 550px) {
  /*#Heading{*/
  /*    font-size: 7.7vw !important;*/
  /*}*/
  #Heading_para {
    font-size: 2.5vw;
  }
  .main_card_container {
    width: 70%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:auto;
  }
  .card_detail_description {
    font-size: 2vw;
  }
  .separator_container {
    font-size: 5vh;
    padding: 1vh;
    margin-right: 30px;
    display:none;
  }
}
@media only screen and (max-width: 430px) {
  #Heading_para {
    font-size: 0.9em;
  }
  .main_card_container {
    width: 50%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:auto;
  }
  .card_detail_title {
    font-size: 3.7vw;
  }
  .card_detail_container_2 {
    bottom: 37vw;
  }
  .card_detail_description{
    font-size: 2.7vw;
  }
  .separator_container {
    font-size: 4vh;
    padding: 1vh;
    margin-right: 30px;
    display: none;
  }
}
