@media (min-width: 720px) {
    .hamburger {
        display: none;
    }
}
@media (max-width: 720px) {

    .hamburger {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 10px;
        cursor: pointer;

    }
    .hamburger > img {
        height: 70%;
    }
}
