html { height: auto; overflow:auto; }

:root {
  font-size: 20px;
}

::selection {
  background: #2ddab8;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  background: #0c0f13;
  font-family: "Poppins", sans-serif;
  color: #8b8b8b;
  font-size: 0.9rem;
  scroll-behavior: smooth;
  overflow:auto;
  height: auto;
}

a {
  color: #545454;
  text-decoration: none;
}

a:focus,
a:hover {
  color: #8b8b8b;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

/*.root {*/
/*  width: 100vw;*/
/*  height: 100vh;*/
/*  overflow: hidden;*/
/*}*/

.loading {
  padding: 10px;
  transform: translate3d(-50%, -50%, 0);
  color: #8b8b8b;
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.frame {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1.5rem;
  z-index: 2;
}

.frame__title {
  font-size: inherit;
  font-weight: normal;
  margin: 0;
}

.frame__links {
  margin: 1rem 0;
}

.frame__link:not(:last-child) {
  margin-right: 1rem;
}

.frame__nav .frame__link:first-child {
  margin-right: 1.5rem;
}

.bottom-left {
  padding: 1.5rem;
  transform: translate3d(0, -100%, 0) !important;
}

.canvas > div {
  z-index: 10000000;
}

/*#root > div > div {*/
/*  pointer-events: none;*/
/*}*/

nav {
  position: relative;
  right: 280px;
}

.line {
  height: 2px;
  position: absolute;
  bottom: 0;
  margin: 10px 0 0 0;
  background: #FF1847;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

li {
  margin: 0 40px 0 0;
  opacity: 0.4;
  transition: all 0.4s ease;
}

li:hover {
  opacity: .7;
}

li.active {
  opacity: 1;
}

li:last-child {
  margin-right: 0;
}

a {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  letter-spacing: .2em;
  font-size: 14px;
}


@media screen and (min-width: 53em) {
  body {
    overflow: hidden;
  }
  .frame {
    display: grid;
    height: 100vh;
    padding: 2rem 3.7rem;
    pointer-events: none;
    align-content: space-between;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "title links"
      "... ..."
      "... nav";
  }
  .frame__title {
    grid-area: title;
  }
  .frame__links {
    grid-area: links;
    justify-self: end;
    margin: 0;
  }
  .frame__link {
    pointer-events: auto;
  }
  .frame__nav {
    grid-area: nav;
    justify-self: end;
  }
  .bottom-left {
    padding: 0 0 2rem 3.7rem;
  }
}
